import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

//redux
import { useSelector, useDispatch } from "react-redux"

const CenterCapacity = ({ dataColors }) => {
  const [seriesData, setSeriesData] = useState([])
  const [labelData, setLabelData] = useState([])
  const { centercapacity, loading } = useSelector(state => state.Dashboard)

  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors)

  useEffect(() => {
    let keys = []
    let series = []
    centercapacity &&
      centercapacity.map(item => {
        keys.push(item["Value"])
        series.push(item["Title"])
      })
    if (keys.length > 0) {
      setSeriesData(keys)
    }
    if (series.length > 0) {
      setLabelData(series)
    }
  }, [centercapacity])

  const series = seriesData
  const options = {
    labels: labelData,
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Center Capacity Chart</h4>

          <div>
            <div id="donut-chart">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={260}
                className="apex-charts"
              />
            </div>
          </div>

          <div className="text-center text-muted">
            <Row>
              <Col xs="6">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-primary me-1" />
                    {labelData && labelData[0]}
                  </p>
                  <h5>{seriesData && seriesData[0]}</h5>
                </div>
              </Col>
              <Col xs="6">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-success me-1" />{" "}
                    {labelData && labelData[1]}
                  </p>
                  <h5>{seriesData && seriesData[1]}</h5>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default CenterCapacity
