import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Spinners from "components/Common/Spinner"
import { getMediaAlbmImagePath } from "helpers/imageService"
import { Gallery } from "react-grid-gallery"
import Lightbox from "react-image-lightbox"
import EmptyContainer from "../../../components/Common/EmptyContainer"
import "react-image-lightbox/style.css"
import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
//custom hooks
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import {
  getAlbumDetail as onGetAlbumDetail,
  deleteAlbumMedia as onDeleteAlbumMedia,
} from "store/actions"
import DeleteModal from "../../../components/Common/DeleteModal"
function MediaAlbumTab({ album, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const [isLoading, setLoading] = useState(loading)
  const data = useSelector(state => state.album)
  const [albummedias, setAlbumMedias] = useState(album?.MediaAlbums)
  // const [albummedia, setAlbumMedia] = useState(null)
  const dispatch = useDispatch()
  //  {getUserImagePath(staff?.RowID, staff?.Photo ?? "")}
  // album?.MediaAlbums
  const [deleteModal, setDeleteModal] = useState(false)
  const [images, setImages] = useState([])

  const [index, setIndex] = useState(-1)

  const currentImage = images[index]
  const nextIndex = (index + 1) % images.length
  const nextImage = images[nextIndex] || currentImage
  const prevIndex = (index + images.length - 1) % images.length
  const prevImage = images[prevIndex] || currentImage

  //const handleClick = (index: Number, item: CustomImage) => setIndex(index);
  const handleClick = (index, item) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  //const handleDelete = index => alert("handleDelete"+ index)

  useDeepCompareEffect(() => {
    function updateAlbumState() {
      setAlbumMedias(album?.MediaAlbums)
      const arrGallery = []
      album?.MediaAlbums.forEach(obj => {
        let newImage = {
          src: getMediaAlbmImagePath(obj?.RowID, obj?.FileName ?? ""),
          original: getMediaAlbmImagePath(obj?.RowID, obj?.FileName ?? ""),
          width: 320,
          height: 320,
          tags: [],
          caption: "",
          AlbumMediaID: obj.AlbumMediaID,
        }
        arrGallery.push(newImage)
      })
      setImages(arrGallery)
    }

    const { id } = routeParams

    if (id === "new") {
    } else {
      updateAlbumState()
    }
  }, [routeParams, albummedias, images, dispatch])

  useEffect(() => {
    setAlbumMedias(album?.MediaAlbums)
  }, [albummedias, dispatch, data])

  // Function to delete an image
  const deleteImage = index => {
    const updatedImages = images[index]
    const { id } = routeParams
    dispatch(onDeleteAlbumMedia(updatedImages?.AlbumMediaID))
    dispatch(onGetAlbumDetail(id))
    setImages(images.splice(index, 1))
    handleClose()
  }
  const handleDelete = () => {
    //if (album && album.AlbumID) {
    // dispatch(onDeleteAlbum(album.AlbumID))
    setDeleteModal(true)
    //handleClose()
    // }
  }
  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <h3>Gallery</h3>

          {images?.length == 0 ? (
            <EmptyContainer backURL="" message="No image found" linkText="" />
          ) : (
            <div>
              <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
              />
              {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                  mainSrc={currentImage.original}
                  imageTitle={currentImage.caption}
                  mainSrcThumbnail={currentImage.src}
                  nextSrc={nextImage.original}
                  nextSrcThumbnail={nextImage.src}
                  prevSrc={prevImage.original}
                  prevSrcThumbnail={prevImage.src}
                  onCloseRequest={handleClose}
                  onMovePrevRequest={handleMovePrev}
                  onMoveNextRequest={handleMoveNext}
                  toolbarButtons={[
                    <Link
                      key="delete"
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        deleteImage(index)
                      }}
                    >
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>,
                  ]}
                />
              )}
            </div>
          )}
          <ToastContainer />
        </>
      )}
    </>
  )
}

export default MediaAlbumTab
