import React from "react"
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody } from "reactstrap"

// //Import Scrollbar
import SimpleBar from "simplebar-react"
import avatar5 from "../../assets/images/users/avatar-5.jpg"

//redux
import { useSelector, useDispatch } from "react-redux"

import { getUserImagePath, getChildImagePath } from "helpers/imageService"

const Messages = () => {
  const { messages } = useSelector(state => state.Dashboard)

  // const activityFeedData = [
  //   {
  //     type: "application",
  //     name: "Charles Brown",
  //     action: "applied for the job",
  //     jobTitle: "Sr.frontend Developer",
  //     timestamp: "3 min ago",
  //     img: avatar5,
  //   },
  //   {
  //     type: "subscription",
  //     message: "Your subscription expires today",
  //     actionLink: "#",
  //     timestamp: "53 min ago",
  //   },
  //   {
  //     type: "accountCreation",
  //     name: "Jennifer Alexandar",
  //     action: "created a new account as a",
  //     accountType: "Freelance",
  //     timestamp: "1 hrs ago",
  //   },
  //   {
  //     type: "application",
  //     name: "Mark Ellison",
  //     action: "applied for the job",
  //     jobTitle: "Project Manager",
  //     timestamp: "3 hrs ago",
  //     img: avatar5,
  //   },
  //   {
  //     type: "accountCreation",
  //     name: "Acolin Zelton",
  //     action: "created a new account as a",
  //     accountType: "Freelance",
  //     timestamp: "1 hrs ago",
  //   },
  // ]

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Activity Feed</h4>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                {(messages || []).map((event, index) => (
                  <li key={index} className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <img
                          src={
                            event.ObjectTypeID == 1
                              ? getChildImagePath(event.RowID, "image.jpeg")
                              : getUserImagePath(event.RowID, "image.jpeg")
                          }
                          alt=""
                          className="avatar-xs rounded-circle"
                        />
                        {/* {event.type === "subscription" && (
                          <div className="avatar-xs">
                            <div className="avatar-title bg-primary-subtle text-primary rounded-circle">
                              <i className="bx bx-revision font-size-14"></i>
                            </div>
                          </div>
                        )}
                        {event.type === "accountCreation" && (
                          <div className="avatar-xs">
                            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                              JA
                            </div>
                          </div>
                        )} */}
                      </div>
                      <div className="flex-grow-1">
                        <div>
                          {(event.Type === "CheckIn" ||
                            event.Type === "CheckOut") && (
                            <>
                              <b>{event.Name}</b> {event.Action}{" "}
                              <b>{event.jobTitle}</b>
                            </>
                          )}
                          {event.type === "subscription" && (
                            <>
                              {event.message}{" "}
                              <a href={event.actionLink}>Renew Now</a>
                            </>
                          )}
                          {event.type === "accountCreation" && (
                            <>
                              <b>{event.name}</b> {event.action}{" "}
                              <b>{event.accountType}</b>.
                            </>
                          )}
                          <p className="mb-0 text-muted">{event.Timestamp}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="text-center mt-4">
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light btn-sm"
                >
                  View More <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Messages
