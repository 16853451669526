import axios from "axios"
import {
  getAccessToken,
  getRefreshToken,
  encryptData,
  getUserName,
} from "./token_helper"
import { call } from "redux-saga/effects"
import qs from "qs"

//apply base url for axios
const API_URL = `${process.env.REACT_APP_WEB_API_URL}`

const axiosApi = axios.create({
  baseURL: API_URL,
})

//axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.request.use(
  req => {
    if (getAccessToken()) {
      req.headers["Authorization"] = `Bearer ${getAccessToken()}`
    }
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

axiosApi.interceptors.response.use(
  response => response,
  err => {
    const originalRequest = err.config
    if (
      err.response &&
      err.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      try {
        // Attempt to refresh the access token using the refresh token
        const refreshToken = getRefreshToken()

        // Update the access token in local storage
        if (getAccessToken()) {
          localStorage.removeItem("authUser")
        }

        const refreshedTokenResponse = post(
          "/auth",
          qs.stringify({
            username: `${getUserName()}`,
            refresh_token: refreshToken,
            grant_type: "refresh_token",
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )

        // Update the access token in local storage
        localStorage.setItem("authUser", encryptData(refreshedTokenResponse))

        // Retry the original request with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${getAccessToken()}`
        return axiosApi(originalRequest)
      } catch (refreshError) {
        console.log(refreshError)
        // If refresh token is also expired or invalid, log the user out
        logoutUser()
        return Promise.reject(refreshError)
      }
    }
    // Pass the error along to the calling code
    return Promise.reject(err.response)
  }
)

async function logoutUser() {
  try {
    localStorage.removeItem("authUser")
    delete axios.defaults.headers.common.Authorization

    // Redirect to login page or perform any other logout logic
    window.location.replace("/login")
  } catch (error) {
    console.error("Logout failed:", error)
    // Handle error, possibly show an error message to the user
  }
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config) {
  return axiosApi.post(url, data, config).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function getFile(url) {
  return await axiosApi
    .get(url, { responseType: "blob" })
    .then(response => response)
}
