import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"
import classnames from "classnames"
import BasicInfoTab from "./tabs/BasicInfoTab"
import MediaAlbumTab from "./tabs/MediaAlbumTab"

import { getAlbumDetail as onGetAlbumDetail } from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"

const AlbumDetail = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")

  useDeepCompareEffect(() => {
    const { id } = routeParams

    setHeader("Album Detail")
    dispatch(onGetAlbumDetail(id))
  }, [dispatch, routeParams])

  //////////////////Album STATE/////////////////////
  const data = useSelector(state => state.album)
  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Album" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(data.album) ? (
            <EmptyContainer
              backURL="/albums"
              message="There is no such album!"
              linkText="Go to Albums Page"
            />
          ) : (
            <>
              <Row>
                <Col sm="12">
                  <h3>{data.album?.Title}</h3>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <MediaAlbumTab {...data} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AlbumDetail
