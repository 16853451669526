import React, { useEffect } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"

import { getAuthUser } from "helpers/token_helper"
import { getAdultImagePath, getUserImagePath } from "helpers/imageService"

import { getProfile as onGetProfile } from "store/actions"
import { isEmpty } from "lodash"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = ({ profile }) => {
  const dispatch = useDispatch()

  //////////////////USER STATE/////////////////////
  const { userprofile } = useSelector(state => state.user)
  //////////////////////////////////////

  useEffect(() => {
    if (!isEmpty(profile)) {
      dispatch(onGetProfile(profile.RowID))
    }
  }, [profile])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Childcare Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="5">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  alt={userprofile.Name}
                  src={
                    userprofile.RoleID === 1 || userprofile.RoleID === 2
                      ? getUserImagePath(userprofile.RowID, "image.jpeg")
                      : getAdultImagePath(userprofile.RowID, "image.jpeg")
                  }
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15">{profile.username}</h5>
              <p className="text-muted mb-0">{profile.RoleType}</p>
            </Col>

            <Col sm="7">
              <div className="pt-4">
                {/* <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">$0</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$0</h5>
                    <p className="text-muted mb-0">Unpaid</p>
                  </Col>
                </Row> */}
                <div className="mt-4">
                  <Link to="/profile" className="btn btn-primary  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
